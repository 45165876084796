// @file Developer tools store

import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { User as UserApi } from '@@/dashboard/padlet_api'
import { HttpCode } from '@@/enums'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { JsonApiError, JsonApiResponse } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import type { FetchError } from '@padlet/fetch'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum DevelopersStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

enum ApiTokenStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

// 16 will not go into a newline on both mobile and still look not too short on desktop
const MASKED_API_TOKEN_LENGTH = 16

export const useSettingsDevelopersStore = defineStore('settingsDevelopers', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()

  // State
  const canIAccess = ref<boolean | null>(null)
  // null state for api token means user cannot access api.
  const apiToken = ref<string | null>(null)
  // Status of the entire page
  const developersStatus = ref(DevelopersStatus.Loading)
  // Status of the api token while generating or refreshing
  const apiTokenStatus = ref(ApiTokenStatus.Completed)

  // Getters
  const isLoadingDevelopersPage = computed(() => developersStatus.value === DevelopersStatus.Loading)
  const isLoadingApiToken = computed(() => apiTokenStatus.value === ApiTokenStatus.Loading)
  const hasApiToken = computed(() => apiToken.value !== null && apiToken.value !== '')
  const canAccessApi = computed(() => canIAccess.value === true)
  const hiddenApiToken = computed(() => {
    if (apiToken.value !== null && apiToken.value !== '') {
      return '•'.repeat(MASKED_API_TOKEN_LENGTH) + apiToken.value.slice(-4)
    }
    return null
  })

  async function canIAccessApi(): Promise<void> {
    try {
      developersStatus.value = DevelopersStatus.Loading
      const { data } = await UserApi.canIAccessApi()
      const canIAccessApi = (data as JsonAPIResource<any>).attributes?.is_authorized
      if (canIAccessApi === true) {
        canIAccess.value = true
      } else {
        canIAccess.value = false
        developersStatus.value = DevelopersStatus.Completed
      }
    } catch (e) {
      const fetchError = e as FetchError
      const response: JsonApiResponse<any> = JSON.parse(fetchError.message)
      const jsonApiError: JsonApiError | undefined = response?.errors?.[0]
      void globalSnackbarStore.setSnackbar({
        message:
          jsonApiError?.title ??
          __('Error %{errorCode} hitting can I access endpoint', { errorCode: fetchError.status }),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'DashboardSettingsDevelopersCanIAccessApi' })
      developersStatus.value = DevelopersStatus.Errored
    }
  }

  async function fetchApiToken(): Promise<void> {
    await canIAccessApi()
    if (canIAccess.value === true) {
      try {
        developersStatus.value = DevelopersStatus.Loading
        const { data } = await UserApi.getApiToken()
        const token = (data as JsonAPIResource<any>).attributes?.token
        apiToken.value = token
        developersStatus.value = DevelopersStatus.Completed
      } catch (e) {
        const fetchError = e as FetchError
        if (fetchError.status === HttpCode.NotFound) {
          apiToken.value = ''
          developersStatus.value = DevelopersStatus.Completed
        } else {
          const response: JsonApiResponse<any> = JSON.parse(fetchError.message)
          const jsonApiError: JsonApiError | undefined = response?.errors?.[0]
          void globalSnackbarStore.setSnackbar({
            message:
              jsonApiError?.title ?? __('Error %{errorCode} fetching API token.', { errorCode: fetchError.status }),
            notificationType: SnackbarNotificationType.error,
          })
          captureFetchException(e, { source: 'DashboardSettingsDevelopersFetchApiToken' })
          developersStatus.value = DevelopersStatus.Errored
        }
      }
    }
  }

  async function generateApiToken(): Promise<void> {
    try {
      apiTokenStatus.value = ApiTokenStatus.Loading
      const { data } = await UserApi.generateApiToken()
      const token = (data as JsonAPIResource<any>).attributes?.token
      apiToken.value = token
      apiTokenStatus.value = ApiTokenStatus.Completed
      void globalSnackbarStore.setSnackbar({
        message: __('API key generated.'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      const fetchError = e as FetchError
      const response: JsonApiResponse<any> = JSON.parse(fetchError.message)
      const jsonApiError: JsonApiError | undefined = response?.errors?.[0]
      void globalSnackbarStore.setSnackbar({
        message: jsonApiError?.title ?? __('Error %{errorCode} generating API token', { errorCode: fetchError.status }),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'DashboardSettingsDevelopersGenerateApiToken' })
      apiTokenStatus.value = ApiTokenStatus.Errored
    }
  }

  async function refreshApiToken(): Promise<void> {
    try {
      apiTokenStatus.value = ApiTokenStatus.Loading
      const { data } = await UserApi.refreshApiToken(apiToken.value)
      const token = (data as JsonAPIResource<any>).attributes?.token
      apiToken.value = token
      apiTokenStatus.value = ApiTokenStatus.Completed
      void globalSnackbarStore.setSnackbar({
        message: __('API key refreshed'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error refreshing token. Please refresh the page and try again.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'DashboardSettingsDevelopersRefreshApiToken' })
      apiTokenStatus.value = ApiTokenStatus.Errored
    }
  }

  function confirmRefreshToken(): void {
    globalConfirmationDialogStore.openConfirmationDialog({
      ...ALERT_ICON,
      title: __('Refresh API token?'),
      body: __('You will genereate a new API token. The old token will no longer work.'),
      confirmButtonText: __('Refresh'),
      cancelButtonText: __('Cancel'),
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
      shouldFadeIn: false,
      forceFullWidthButtons: true,
      afterConfirmActions: [refreshApiToken],
    })
  }

  return {
    // State
    apiToken,

    // Getters
    isLoadingDevelopersPage,
    isLoadingApiToken,
    hasApiToken,
    canAccessApi,
    hiddenApiToken,

    // Actions
    fetchApiToken,
    generateApiToken,
    refreshApiToken,
    confirmRefreshToken,
  }
})

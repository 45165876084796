// @file Settings integrations store

import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { User as UserApi } from '@@/dashboard/padlet_api'
import { HttpCode } from '@@/enums'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { JsonApiError, JsonApiResponse } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import type { FetchError } from '@padlet/fetch'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum ZapierApiTokenStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

// 16 will not go into a newline on both mobile and still look not too short on desktop
const MASKED_ZAPIER_API_TOKEN_LENGTH = 16

export const useSettingsIntegrationsStore = defineStore('settingsIntegrations', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()

  // State
  // null state for zapier api token means user cannot access api.
  const zapierApiToken = ref<string | null>(null)
  // Status of the api token while generating or refreshing
  const zapierApiTokenStatus = ref(ZapierApiTokenStatus.Completed)
  const canAccessZapierApi = computed(() => isAppUsing('zapierIntegration'))
  // Getters
  const isLoadingZapierApiToken = computed(() => zapierApiTokenStatus.value === ZapierApiTokenStatus.Loading)
  const hiddenZapierApiToken = computed(() => {
    if (zapierApiToken.value !== null && zapierApiToken.value !== '') {
      return '•'.repeat(MASKED_ZAPIER_API_TOKEN_LENGTH) + zapierApiToken.value.slice(-4)
    }
    return null
  })

  const hasZapierApiToken = computed(() => zapierApiToken.value !== null && zapierApiToken.value !== '')

  // Actions
  async function fetchZapierApiToken(): Promise<void> {
    try {
      const { data } = await UserApi.getZapierApiToken()
      const token = (data as JsonAPIResource<any>).attributes?.token
      zapierApiToken.value = token
    } catch (e) {
      const fetchError = e as FetchError
      if (fetchError.status === HttpCode.NotFound) {
        zapierApiToken.value = ''
      } else {
        const response: JsonApiResponse<any> = JSON.parse(fetchError.message)
        const jsonZapierApiError: JsonApiError | undefined = response?.errors?.[0]
        void globalSnackbarStore.setSnackbar({
          message:
            jsonZapierApiError?.title ??
            __('Error %{errorCode} fetching zapier API token.', { errorCode: fetchError.status }),
          notificationType: SnackbarNotificationType.error,
        })
        captureFetchException(e, { source: 'DashboardSettingsIntegrationsFetchZapierApiToken' })
      }
    }
  }

  async function generateZapierApiToken(zapierApiKey: boolean = false): Promise<void> {
    try {
      zapierApiTokenStatus.value = ZapierApiTokenStatus.Loading
      const { data } = await UserApi.generateZapierApiToken(zapierApiKey)
      const token = (data as JsonAPIResource<any>).attributes?.token
      zapierApiToken.value = token
      zapierApiTokenStatus.value = ZapierApiTokenStatus.Completed
      void globalSnackbarStore.setSnackbar({
        message: __('Zapier API key generated.'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      const fetchError = e as FetchError
      const response: JsonApiResponse<any> = JSON.parse(fetchError.message)
      const jsonZapierApiError: JsonApiError | undefined = response?.errors?.[0]
      void globalSnackbarStore.setSnackbar({
        message:
          jsonZapierApiError?.title ??
          __('Error %{errorCode} generating Zapier API token', { errorCode: fetchError.status }),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'DashboardSettingsIntegrationsGenerateZapierApiToken' })
      zapierApiTokenStatus.value = ZapierApiTokenStatus.Errored
    }
  }

  async function refreshZapierApiToken(): Promise<void> {
    try {
      zapierApiTokenStatus.value = ZapierApiTokenStatus.Loading
      const { data } = await UserApi.refreshZapierApiToken(zapierApiToken.value)
      const token = (data as JsonAPIResource<any>).attributes?.token
      zapierApiToken.value = token
      zapierApiTokenStatus.value = ZapierApiTokenStatus.Completed
      void globalSnackbarStore.setSnackbar({
        message: __('Zapier API key refreshed'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error refreshing token. Please refresh the page and try again.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'DashboardSettingsIntegrationsRefreshZapierApiToken' })
      zapierApiTokenStatus.value = ZapierApiTokenStatus.Errored
    }
  }

  async function revokeZapierApiToken(): Promise<void> {
    try {
      await UserApi.revokeZapierApiToken(zapierApiToken.value)
      zapierApiToken.value = null
      zapierApiTokenStatus.value = ZapierApiTokenStatus.Completed
      void globalSnackbarStore.setSnackbar({
        message: __('Zapier API key revoked.'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error revoking token. Please refresh the page and try again.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'DashboardSettingsIntegrationsRevokeZapierApiToken' })
      zapierApiTokenStatus.value = ZapierApiTokenStatus.Errored
    }
  }

  function confirmRefreshZapierApiToken(): void {
    globalConfirmationDialogStore.openConfirmationDialog({
      ...ALERT_ICON,
      title: __('Refresh Zapier API token?'),
      body: __('You will genereate a new Zapier API token. The old token will no longer work.'),
      confirmButtonText: __('Refresh'),
      cancelButtonText: __('Cancel'),
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
      shouldFadeIn: false,
      forceFullWidthButtons: true,
      afterConfirmActions: [refreshZapierApiToken],
    })
  }

  function confirmRevokeZapierApiToken(): void {
    globalConfirmationDialogStore.openConfirmationDialog({
      ...ALERT_ICON,
      title: __('Revoke Zapier API token?'),
      body: __('You will revoke the current Zapier API token. The token will no longer work.'),
      confirmButtonText: __('Revoke'),
      cancelButtonText: __('Cancel'),
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
      shouldFadeIn: false,
      forceFullWidthButtons: true,
      afterConfirmActions: [revokeZapierApiToken],
    })
  }

  return {
    // State
    zapierApiToken,

    // Getters
    isLoadingZapierApiToken,
    hasZapierApiToken,
    canAccessZapierApi,
    hiddenZapierApiToken,

    // Actions
    fetchZapierApiToken,
    generateZapierApiToken,
    refreshZapierApiToken,
    confirmRefreshZapierApiToken,
    confirmRevokeZapierApiToken,
  }
})
